<template>
  <div
    class="datepicker__wrapper"
    :class="{
      'icon-right': iconPosition === EDatePickerIconPosition.Right,
      'datepicker__wrapper--invalid': internalErrorMessage
    }"
  >
    <DatePicker
      ref="datepickerRef"
      :model-value="modelValue"
      locale="ru"
      select-text="Выбрать"
      cancel-text="Отменить"
      v-bind="$attrs"
      :disabled="disabled"
      @focus="onFocusPicker"
      @blur="onBlurPicker"
      @internal-model-change="onInputPicker"
    >
      <template #input-icon>
        <SvgIcon src="action/date" />
      </template>
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
        :key="name"
      >
        <ClientOnly>
          <slot
            :name="name"
            v-bind="slotData"
          />
        </ClientOnly>
      </template>
      <template #year="{ year }">
        {{ year }}
      </template>

      <template
        #dp-input="{
          value,
          onInput,
          onEnter,
          onTab,
          onBlur,
          onKeypress,
          onPaste,
          onFocus
        }"
      >
        <input
          ref="inputRef"
          class="dp__input dp__input_icon_pad"
          type="text"
          :value="value"
          autocomplete="off"
          :placeholder="placeholder"
          @input="onInternalInput($event, onInput)"
          @keypress.enter="onEnter"
          @keypress.tab="onTab"
          @blur="onBlur"
          @focus="onFocus"
          @keypress="onKeypress"
          @paste="onPaste"
        >
        <!-- {{ value }} -->
      </template>
    </DatePicker>

    <div
      v-if="internalErrorMessage"
      class="datepicker__error"
    >
      {{ internalErrorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
interface _VTI_TYPE_IDatePickerMonthYear {
  month: number | string;
  year: number | string;
}
interface _VTI_TYPE_IDatePickerTime {
  hours: number | string;
  minutes: number | string;
  seconds?: number | string;
}
type _VTI_TYPE_EDatePickerIconPosition = /* enum */ number
type _VTI_TYPE_DatepickerValue = | _VTI_TYPE_Date
  | _VTI_TYPE_Array
  | string
  | _VTI_TYPE_Array
  | _VTI_TYPE_IDatePickerTime
  | _VTI_TYPE_Array
  | _VTI_TYPE_IDatePickerMonthYear
  | _VTI_TYPE_Array
  | null
interface IDatepickerProps {
  modelValue?: _VTI_TYPE_DatepickerValue;
  validationField?: _VTI_TYPE_FieldContext;
  iconPosition?: _VTI_TYPE_EDatePickerIconPosition;
  disabled?: boolean;
  errorMessage?: string;
  placeholder?: string;
}
import DatePicker from '@vuepic/vue-datepicker'
import { EDatePickerIconPosition } from '../enums/datePickerIconPosition.enum'
import IMask from 'imask'
/**
 * Обертка над библиотекой @vuepic/vue-datepicker
 * Пропсы: https://vue3datepicker.com/api/props/
 * Слоты: https://vue3datepicker.com/api/slots/
 * События: https://vue3datepicker.com/api/events/
 */
const isFocusInput = ref(false);
const inputValue = ref<Date | undefined>();
const mask = ref<IMask.InputMask<IMask.MaskedDateOptions> | undefined>();
const datepickerRef = ref();
const inputRef = ref<HTMLInputElement | undefined>();
const props = withDefaults(defineProps<IDatepickerProps>(), {
  iconPosition: 0 as EDatePickerIconPosition,
});
const internalErrorMessage = computed(
  () => props.validationField?.errorMessage.value || props.errorMessage,
);
function onFocusPicker(): void {
  isFocusInput.value = true;
}
function onBlurPicker(): void {
  isFocusInput.value = false;
}
function onInputPicker(event: Date | undefined): void {
  if (isFocusInput.value) {
    inputValue.value = event;
  }
}
function initMask(): void {
  if (!inputRef.value) {
    return;
  }
  mask.value = IMask(
    inputRef.value,
    {
      mask: Date,
      pattern: 'd{.}`m{.}`Y',
      lazy: false,
      autofix: false,
      overwrite: false,
      blocks: {
        d: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          maxLength: 2,
        },
        m: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
        },
        Y: {
          mask: IMask.MaskedRange,
          from: 1970,
          to: 9999,
        },
      },
    },
  );
}
function onInternalInput(event: Event, onInput: (e: Event) => void): void {
  if (!mask.value) {
    return;
  }
  const target = event.target as HTMLInputElement;
  mask.value.value = target.value;
  onInput({
    ...event,
    target: {
      ...event.target,
      value: mask.value.unmaskedValue,
    },
  });
}
onMounted(() => initMask());
defineExpose({ datepickerRef, inputValue });
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.datepicker {
  &__wrapper {
    display: flex;
    flex-direction: row;
    position: relative;

    &.icon-right {
      :deep(.dp__input_icon) {
        position: relative;
        bottom: 42px;
        left: calc(100% - 40px);
      }
    }

    :deep(.dp__main) {
      .dp__input {
        border: 1px solid #E6E6E6;
        border-radius: 6px;
        font-size: 14px;

        &:hover {
          border-color: $text-disabled;
        }
      }

      .dp__icon {
        top: 52%;
      }
    }
  }

  &__error {
    font-size: 12px;
    line-height: 16px;
    color: $input-error-c;
    position: absolute;
    bottom: 0;
    left: 16px;
  }
}
</style>
